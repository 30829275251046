<template>
  <div>
    <b-tabs
      content-class="pt-1"
      fill
    >
      <b-tab
        title="PENDIENTES"
      >
        <b-row>
          <b-col
            v-for="(aplomado, o) in filtrarData('PENDIENTE')"
            :key="aplomado.id"
            cols="12"
          >
            <card-aplomado
              :id="aplomado.id"
              :key="aplomado.id"
              :aplomado="aplomado"
              :contador="o+1"
              :operacion="'N'"
              @refrescar-pagina="reaload()"
            />
          </b-col>
        </b-row>
      </b-tab>
      <b-tab
        title="EN CURSO"
      >
        <b-row>
          <b-col
            v-for="(aplomado, o) in filtrarData('EN CURSO')"
            :key="aplomado.id"
            cols="12"
          >
            <card-aplomado
              :id="aplomado.id"
              :key="aplomado.id"
              :aplomado="aplomado"
              :contador="o+1"
              :operacion="'U'"
              @refrescar-pagina="reaload()"
            />
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BTabs, BTab, BRow, BCol,
} from 'bootstrap-vue'
import CardAplomado from '@/components/mantenimiento/aplomadoPoste/card-aplomado.vue'
import { findAplomado } from '@/utils/mantenimiento/aplomadoPoste/aplomadoPosteUtils'

export default {
  components: {
    CardAplomado,
    BTabs,
    BTab,
    BRow,
    BCol,
  },
  data() {
    return {
      aplomados: [],
      usuario: JSON.parse(localStorage.getItem('userData')),
    }
  },
  beforeMount() {
    this.reaload()
  },
  methods: {
    async reaload() {
      const filter = {
        where: {
          and: [
            { companyId: this.usuario.idCompany },
            { or: [{ estado: 'PENDIENTE' }, { estado: 'EN CURSO' }] },
          ],
        },
        order: ['fechaCreacion DESC'],
      }
      this.aplomados = await findAplomado(filter)
    },
    filtrarData(status) {
      return this.aplomados.filter(aplomado => aplomado.estado === status)
    },
  },
}

</script>

<style scoped>

</style>
