<template>
  <div>
    <b-button
      v-if="aplomado.estado === 'PENDIENTE'"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="primary"
      block
      @click="abrirModal('modal-1')"
    >
      Ingreso de Seguimiento de Aplomado de Poste
    </b-button>
    <b-button
      v-if="aplomado.estado === 'EN CURSO' && aplomado.seguimiento.length !== 2"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="primary"
      block
      @click="abrirModal('modal-2')"
    >
      Ingresar Fotografía de Desmontaje
    </b-button>
    <b-button
      v-if="aplomado.estado === 'EN CURSO' && aplomado.seguimiento.length === 2"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="primary"
      block
      @click="abrirModal('modal-3')"
    >
      Finalizar Aplomado de Poste
    </b-button>
    <b-modal
      id="modal-1"
      ref="modal"
      title="Seguimiento de Aplomado"
      :no-close-on-backdrop="true"
      hide-footer
      @hide="cerrarModal"
      @close="cerrarModal"
    >
      <b-card-text>
        <b-overlay
          :show="show"
        >
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row v-if="modalActivo === 'modal-1'">
                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Desmontaje"
                      rules="required"
                    >
                      <h6 class="text-primary">
                        Desmontaje
                      </h6>
                      <v-select
                        v-model="aplomado.trabajosDesmontaje"
                        label="nombre"
                        multiple
                        :state="errors.length > 0 ? false:null"
                        :options="montaje"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Montaje"
                      rules="required"
                    >
                      <h6 class="text-primary">
                        Montaje
                      </h6>
                      <v-select
                        v-model="aplomado.trabajosMontaje"
                        label="nombre"
                        multiple
                        :state="errors.length > 0 ? false:null"
                        :options="montaje"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  sm="12"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Comentarios del Aplomado de Poste"
                      rules="required"
                    >
                      <h6 class="text-primary">
                        Aplomado de Poste
                      </h6>
                      <b-form-textarea
                        v-model="comentarioAplomado"
                        :state="errors.length > 0 ? false:null"
                        no-resize
                        rows="2"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-else-if="modalActivo === 'modal-2'">
                <b-col
                  v-if="showPanoramicaDesmontaje"
                  cols="12"
                >
                  <h6
                    class="text-primary"
                  >
                    Fotografía Panorámica del Desmontaje
                  </h6>
                  <Imagen
                    :key="'foto-aplomado'"
                    leyenda="'imagen-aplomado-desmontaje'"
                    @cargar-file="fotoPanoramicaDesmontaje"
                  />
                </b-col>
                <b-col
                  cols="12"
                  sm="12"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Comentarios del Desmontaje"
                      rules="required"
                    >
                      <h6 class="text-primary">
                        Comentarios del Desmontaje
                      </h6>
                      <b-form-textarea
                        v-model="comentarioDesmontaje"
                        :state="errors.length > 0 ? false:null"
                        no-resize
                        rows="2"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-else-if="modalActivo === 'modal-3'">
                <b-col
                  v-if="showPanoramicaMontaje"
                  cols="12"
                >
                  <h6
                    class="text-primary"
                  >
                    Fotografía Panorámica del Después
                  </h6>
                  <Imagen
                    leyenda="imagen-aplomado-montaje"
                    @cargar-file="fotoPanoramicaMontaje"
                  />
                </b-col>
                <b-col
                  cols="12"
                  sm="12"
                >
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Comentarios del Después del Aplomado"
                      rules="required"
                    >
                      <h6 class="text-primary">
                        Comentarios Después del Aplomado
                      </h6>
                      <b-form-textarea
                        v-model="comentarioFinal"
                        :state="errors.length > 0 ? false:null"
                        no-resize
                        rows="2"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <hr>
              <b-row>
                <b-col
                  style="display: flex; justify-content: end"
                  cols="12"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-0 mr-0 align-self-center"
                    @click="validationForm"
                  >
                    <feather-icon
                      icon="SaveIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">Guardar Seguimiento</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-overlay>
      </b-card-text>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow, BCol, BForm, BFormGroup, BModal, BButton, VBModal, BFormTextarea, BOverlay, BCardText,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Imagen from '@/components/Inspecciones/Imagen.vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import vSelect from 'vue-select'
import { getListadoAplomadoPoste, updateCreateAplomado } from '@/utils/mantenimiento/aplomadoPoste/aplomadoPosteUtils'
import { mensajeError, mensajeInformativo } from '@/utils/mensajes'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { saveImagen } from '@/utils/imagenes'
import router from '@/router'

export default {
  components: {
    Imagen,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormTextarea,
    BModal,
    BButton,
    BOverlay,
    BCardText,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    aplomado: {
      type: Object,
      required: true,
    },
    operacion: {
      type: String,
      required: true,
    },
    item: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      modalActivo: null,
      usuario: JSON.parse(localStorage.getItem('userData')),
      required,
      show: false,
      trabajosAplomado: [],
      aplomadoPoste: {
        id: router.currentRoute.params.poste,
        usuarioCreacion: JSON.parse(localStorage.getItem('userData')),
        companyId: JSON.parse(localStorage.getItem('userData')).idCompany,
        fechaCreacion: new Date(),
        brigada: null,
        lugar: null,
        poste: null,
        trabajosDesmontaje: [],
        trabajosMontaje: [],
        estado: '',
        seguimiento: [],
        tracking: [
          {
            estado: 'INGRESADA',
            horaFecha: new Date(),
          },
        ],
      },
      comentarioAplomado: null,
      comentarioDesmontaje: null,
      comentarioFinal: null,
      panoramicaDesmontaje: '',
      panoramicaMontaje: '',
      showPanoramicaDesmontaje: false,
      showPanoramicaMontaje: false,
    }
  },
  watch: {
    modalActivo: {
      handler(value) {
        if (value === 'modal-1') {
          this.showPanoramicaMontaje = false
          this.showPanoramicaDesmontaje = false
        } else if (value === 'modal-2') {
          this.showPanoramicaMontaje = false
          this.showPanoramicaDesmontaje = true
        } else if (value === 'modal-3') {
          this.showPanoramicaMontaje = true
          this.showPanoramicaDesmontaje = false
        }
      },
    },
  },
  async created() {
    this.montaje = getListadoAplomadoPoste(1)
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        // eslint-disable-next-line no-return-assign
        pos => (
          this.localization = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          }),
        error => {
          console.error('Error Obteniendo Latitud y Longitud!', error)
          this.localization = null
        },
      )
    }
  },
  methods: {
    async guardarAplomado() {
      if (this.showPanoramicaDesmontaje) {
        if (!this.panoramicaDesmontaje || this.panoramicaDesmontaje === '') {
          mensajeError('Debe Ingresar una Fotografía Panorámica del Desmontaje!')
          return
        }
      }
      if (this.showPanoramicaMontaje) {
        if (!this.panoramicaMontaje || this.panoramicaMontaje === '') {
          mensajeError('Debe Ingresar una Fotografía Panorámica del Montaje!')
          return
        }
      }
      this.show = true

      if (this.localization === null || this.localization === undefined) {
        mensajeError('Error obteniendo geolocalización, intente nuevamente!')
        return
      }

      this.aplomadoPoste.usuario = {
        id: this.usuario.id,
        nombre: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
        name: `${this.usuario.firstName} ${this.usuario.firstSurname}`,
        email: this.usuario.email,
        telefono: '',
      }

      const body = { ...this.aplomado }
      const nuevasFotos = []

      // eslint-disable-next-line prefer-const
      let item = body

      if (this.modalActivo === 'modal-1') {
        item.estado = 'EN CURSO'
        item.tracking.push({ estado: 'EN CURSO', horaFecha: new Date() })
      } else if (this.modalActivo === 'modal-3') {
        item.estado = 'FINALIZADO'
        item.tracking.push({ estado: 'FINALIZADO', horaFecha: new Date() })
      }

      const urlBucket = `aplomados/${item.id}`
      const isMobile = window.screen.width <= 760

      if (this.panoramicaMontaje && this.panoramicaMontaje !== '') {
        const imagen2 = await saveImagen(urlBucket, this.localization, this.panoramicaMontaje, 'Foto Panorámica del Después', this.comentarioFinal, this.aplomadoPoste.usuario, isMobile)
        if (imagen2) item.seguimiento.push(imagen2)
      }
      if (this.panoramicaDesmontaje && this.panoramicaDesmontaje !== '') {
        const imagen3 = await saveImagen(urlBucket, this.localization, this.panoramicaDesmontaje, 'Foto Panorámica del Desmontaje', this.comentarioDesmontaje, this.aplomadoPoste.usuario, isMobile)
        if (imagen3) item.seguimiento.push(imagen3)
      }

      body.seguimiento = [...nuevasFotos, ...body.seguimiento]

      if (this.comentarioAplomado && this.comentarioAplomado.trim() !== '') {
        body.aplomadoPoste = this.comentarioAplomado
      }

      await updateCreateAplomado(item, 1)
      mensajeInformativo('Aplomado', 'Aplomado Actualizado Correctamente!')
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Aplomado de Poste Actualizado!',
          icon: 'CheckIcon',
          variant: 'success',
          text: 'Aplomado de Poste Actualizado Correctamente!',
        },
      })
      this.cerrarModal()
      this.$emit('guardado')
      this.show = false
    },
    fotoPanoramicaDesmontaje(file) {
      this.panoramicaDesmontaje = file
    },
    fotoPanoramicaMontaje(file) {
      this.panoramicaMontaje = file
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.guardarAplomado()
        }
      })
    },
    abrirModal(idModal) {
      if (idModal === 'modal-1') {
        this.aplomadoPoste.estado = 'EN CURSO'
      } else if (idModal === 'modal-2') {
        this.aplomadoPoste.estado = 'FINALIZADA'
      }

      this.modalActivo = idModal
      this.$refs.modal.show()
    },
    cerrarModal() {
      this.panoramicaDesmontaje = ''
      this.panoramicaMontaje = ''
      this.comentarioDesmontaje = ''
      this.comentarioFinal = ''
      this.$refs.modal.hide()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
