<template>
  <div>
    <b-card
      :header="`Poste No. ${aplomado.poste}`"
      :title="`Aplomado No. ${aplomado.id}`"
      :footer="`${getDaysAplomado()}`"
      class="text-center"
      :footer-class="getClassFooterAplomado(aplomado)"
    >
      <b-row>
        <b-col cols="4">
          <span
            class="float-left"
            style="font-size: x-small"
          >Poste No.</span>
        </b-col>
        <b-col cols="8">
          <b-form-textarea
            :value="aplomado.poste"
            plaintext
            style="font-size: x-small"
            no-resize
            class="float-left"
          />
        </b-col>
        <b-col cols="4">
          <span
            class="float-left"
            style="font-size: x-small"
          >Brigada</span>
        </b-col>
        <b-col cols="8">
          <b-form-textarea
            :value="aplomado.brigada.nombre"
            plaintext
            style="font-size: x-small"
            no-resize
            class="float-left"
          />
        </b-col>
        <b-col
          v-if="aplomado.trabajosMontaje && aplomado.trabajosMontaje.length"
          cols="4"
        >
          <span
            class="float-left"
            style="font-size: x-small"
          >Trabajos Montaje</span>
        </b-col>
        <b-col
          v-if="aplomado.trabajosMontaje && aplomado.trabajosMontaje.length"
          cols="8"
        >
          <b-form-textarea
            :value="obtenerTrabajosMontaje()"
            plaintext
            style="font-size: x-small"
            no-resize
            class="float-left"
          />
        </b-col>
        <b-col
          v-if="aplomado.trabajosDesmontaje && aplomado.trabajosDesmontaje.length"
          cols="4"
        >
          <span
            class="float-left"
            style="font-size: x-small"
          >Trabajos Desmontaje</span>
        </b-col>
        <b-col
          v-if="aplomado.trabajosDesmontaje && aplomado.trabajosDesmontaje.length"
          cols="8"
        >
          <b-form-textarea
            :value="obtenerTrabajosDesmontaje()"
            plaintext
            style="font-size: x-small"
            no-resize
            class="float-left"
          />
        </b-col>
        <b-col cols="4">
          <span
            class="float-left"
            style="font-size: x-small"
          >Dirección</span>
        </b-col>
        <b-col cols="8">
          <b-form-textarea
            :value="aplomado.lugar"
            plaintext
            no-resize
            style="font-size: x-small"
            class="float-left"
          />
        </b-col>
        <b-col
          v-if="aplomado.aplomadoPoste && aplomado.aplomadoPoste"
          cols="4"
        >
          <span
            class="float-left"
            style="font-size: x-small"
          >Aplomado de Poste</span>
        </b-col>
        <b-col
          v-if="aplomado.aplomadoPoste && aplomado.aplomadoPoste"
          cols="8"
        >
          <b-form-textarea
            :value="aplomado.aplomadoPoste"
            plaintext
            style="font-size: x-small"
            no-resize
            class="float-left"
          />
        </b-col>
      </b-row>
      <br>
      <ModalAplomado
        v-if="operacion ==='N' || showFinishButton()"
        :aplomado="aplomado"
        :operacion="showFinishButton()? 'F' : operacion"
        @guardado="reaload()"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCol, BRow, BCard, BFormTextarea,
} from 'bootstrap-vue'
import ModalAplomado from '@/components/mantenimiento/aplomadoPoste/modal-aplomado.vue'
import { getClassFooter, getDays, getMinutes } from '@/utils/fechas'

export default {
  components: {
    BCol,
    BRow,
    BFormTextarea,
    ModalAplomado,
    BCard,
  },
  props: {
    aplomado: {
      type: Object,
      required: true,
    },
    operacion: {
      type: String,
      required: true,
    },
    contador: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      punto: {},
      fechaCreacion: '',
      fields: ['item', 'seguimiento'],
    }
  },
  methods: {
    obtenerTrabajosMontaje() {
      if (this.aplomado && this.aplomado.trabajosMontaje) {
        return this.aplomado.trabajosMontaje.map(trabajo => trabajo.nombre).join(', ')
      }
      return ''
    },
    obtenerTrabajosDesmontaje() {
      if (this.aplomado && this.aplomado.trabajosDesmontaje) {
        return this.aplomado.trabajosDesmontaje.map(trabajo => trabajo.nombre).join(', ')
      }
      return ''
    },
    reaload() {
      this.$emit('refrescar-pagina')
    },
    getMinutesAplomado() {
      return getMinutes(this.aplomado)
    },
    getDaysAplomado() {
      const minutos = this.getMinutesAplomado()
      return getDays(minutos)
    },
    showFinishButton() {
      return this.aplomado.estado === 'EN CURSO'
    },
    getClassFooterAplomado() {
      const minutos = this.getMinutesAplomado()
      return getClassFooter(this.aplomado, minutos)
    },
  },
}
</script>
